.screen-loading {
  .top-loading {
    background: linear-gradient(-45deg, #ee5252, #e7b13c, #a2d523, #d5c623);
    background-size: 500% 500%;
    animation: gradient 2s ease-out infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
